export function usePipedrive() {
    
    const config = useRuntimeConfig();
    const key = encodeURIComponent(`${config.public.pipedrive}`)
    const router = useRouter()
    const gtm = useGtm()

    async function searchPersons(data:any) {

        console.log('Search Person')

        const term = encodeURIComponent(data.howShouldWeGetInTouch.emailAddress)

        const url = `https://epcimprovements.pipedrive.com/v1/persons/search?term=${term}&api_token=${key}`

        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const output = await response.json()

        if(output && output.data && output.data.items && output.data.items[0] && output.data.items[0].item) {
            
            await updatePerson(data, output.data.items[0].item.id)

        } else {

            await addPerson(data)

        }

    }

    /* Add Person */
    async function addPerson(data:any) {

        console.log('Add Person')

        let body = {
            name: data.howShouldWeGetInTouch.fullName,
            owner_id: 13188039,
            phone:  data.howShouldWeGetInTouch.contactNumber,
            email: {
                value: data.howShouldWeGetInTouch.emailAddress,
            },
            postal_address: data.findYourProperty.address,
            marketing_status: 'no_consent'
        }

        if(data.howShouldWeGetInTouch.emailMarketing) {
            body = {...body, marketing_status: 'subscribed'}
        }

        const url = `https://epcimprovements.pipedrive.com/v1/persons?api_token=${key}`

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        await addLead(data, output.data.id)

    }

    /* Update Person */
    async function updatePerson(data:any, value:string) {

        console.log('Update Person')

        const id = encodeURIComponent(value)

        let body = {
            name: data.howShouldWeGetInTouch.fullName,
            owner_id: 13188039,
            phone:  data.howShouldWeGetInTouch.contactNumber,
            email: {
                value: data.howShouldWeGetInTouch.emailAddress,
            },
            postal_address: data.findYourProperty.address,
            marketing_status: 'no_consent'
        }

        if(data.howShouldWeGetInTouch.emailMarketing) {
            body = {...body, marketing_status: 'subscribed'}
        }

        const url = `https://epcimprovements.pipedrive.com/v1/persons/${id}?api_token=${key}`

        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        await addLead(data, output.data.id)

    }

    /* Add Lead */
    async function addLead(data:any, value:string) {

        console.log('Add Lead')

        const id = encodeURIComponent(value)

        let body = {

            title: `${data.howShouldWeGetInTouch.fullName} - ${data.findYourProperty.postcode}`,
            owner_id: 13188039,
            person_id: Number(id),

            /* Ownership Status */
            '9636ea382ce5ec30da6e047a11357ebd11a4292c': Number(data.doYouOwnYourHome.option),

            /* Solor Bundle */
            '6db7e6cdb388cb84512e85e88fbe11caf2d43f7a': Number(data.whichSolarBundleAreYouIntrestedIn.option),

            /* Time Frame */
            '1a4277605e41599d7f9be2b25db2197c0391804a': Number(data.whatIsYourIdealInstallationTimeframe.option),

            /* Communication Preference */
            '6ca441ea28ac7d796196e70c0af711fd55a972ec': data.howShouldWeGetInTouch.options.toString(),

            /* Marketing Status */
            '4065f204081059861a342eceefcfd64f9d5bda96': Number(data.howShouldWeGetInTouch.emailMarketing ? 1622 : 1623),

            /* Customer Lead Source */
            '4d31aeab3647ae26d088f3d55d884cd3525da8d7': 1607, //Landing Page - Solar

        }
        
        const url = `https://epcimprovements.pipedrive.com/v1/leads?api_token=${key}`

        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        if(data.extraInformation.value) {
            await addNotes(data, output.data.id)
        } else {

            gtm?.trackEvent({
                event: 'formSubmission',
                label: `Get a Quote - Solar - Form Submission`,
            })

            router.push({ path: "/get-a-quote/solar/thank-you" })

        }

    }

    /* Add Notes (Optinal) */
    async function addNotes(data:any, value:string) {

        console.log('Add Notes')

        const id = encodeURIComponent(value)

        let body = {
            lead_id: id,
            content: data.extraInformation.value
        }

        const url = `https://epcimprovements.pipedrive.com/v1/notes?api_token=${key}`

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        gtm?.trackEvent({
            event: 'formSubmission',
            label: `Get a Quote - Solar - Form Submission`,
        })

        router.push({ path: "/get-a-quote/solar/thank-you" })

    }

    /* Subscribe */
    async function subscribe(name:string, email:string){

        const term = encodeURIComponent(email)

        const url = `https://epcimprovements.pipedrive.com/v1/persons/search?term=${term}&api_token=${key}`

        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        let output = await response.json()

        if(output && output.data && output.data.items && output.data.items[0] && output.data.items[0].item) {
            
            const id = encodeURIComponent(output.data.items[0].item.id)
    
            let body = {
                name: name,
                owner_id: 13188039,
                email: {
                    value: email,
                },
                marketing_status: 'subscribed'
            }
  
            const url = `https://epcimprovements.pipedrive.com/v1/persons/${id}?api_token=${key}`
    
            const response = await fetch(url, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
    
            output = await response.json()

            return output

        } else {

            let body = {
                name: name,
                owner_id: 13188039,
                email: {
                    value: email,
                },
                marketing_status: 'subscribed'
            }

            const url = `https://epcimprovements.pipedrive.com/v1/persons?api_token=${key}`
    
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
    
            output = await response.json()
            
            return output
        
        }

    }

    return {
        searchPersons,
        addPerson,
        updatePerson,
        addLead,
        subscribe
    }

}